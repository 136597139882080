input[type='submit'],
button {
  border-radius: 5px;
  padding: 16px 32px;
  border: none;
  font-size: 18px;
  margin-bottom: 15px;
  display: block;
  font-family: 'Roboto';
  font-weight: 700;
  color: #fff;
  background-color: #020202;
  cursor: pointer;
}

input:disabled,
input[disabled] {
  opacity: 0.75;
  cursor: default;
}