#formId {
  width: 100px;
  height: 100px;
  background: blue;
}

.file-input label {
  display: block;
  position: relative;
  width: 200px;
  border-radius: 5px;
  background: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #020202;
  font-weight: bold;
  border: 2px solid #020202;
  cursor: pointer;
  padding: 16px 32px;
  font-size: 18px;
  margin-bottom: 15px;
}

.file {
  opacity: 0;
  width: 0.1px;
  height: 0.1px;
  position: absolute;
}