/* Select Button */

.select-button {
  overflow: hidden;
  border-radius: 5px;
  display: inline-block;
  border: 1px solid #020202;
  margin-bottom: 15px;
  input[type='radio'] {
    display: none;
    &:checked + label {
      background: #020202;
      color: #fff;
    }
  }
  label {
    border-radius: 0;
    padding: 16px 32px;
    border: none;
    font-size: 18px;
    margin-bottom: 0;
    font-family: 'Roboto';
    font-weight: 700;
    color: #fff;
    background: transparent;
    color: #020202;
    //background-color: #020202;
    display: inline-block;
    cursor: pointer;
  }
}
