.termin-row {
  border-bottom: 1px solid #020202;
  margin-bottom: 30px;
  padding-bottom: 15px;
}

.button-wrapper {
  display: flex;
  button {
    &:first-child {
      margin-right: 15px;
    }
  }
}
