@font-face {
  font-family: 'Roboto';
  font-weight: 500;
  src: url(../public/fonts/Roboto-Medium.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 400;
  src: url(../public/fonts/Roboto-Regular.ttf);
}

@font-face {
  font-family: 'Roboto';
  font-weight: 700;
  src: url(../public/fonts/Roboto-Bold.ttf);
}

h1 {
  font-family: 'Roboto';
  font-weight: 600;
  font-size: 48px;
  margin: 0;
  line-height: 1.5;
}

header {
  padding: 15px;
}

header img {
  max-width: 150px;
}

.form-container {
  padding-top: 60px;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.columns-2 {
  display: flex;
  justify-content: space-between;
}

p {
  font-family: 'Roboto';
  margin: 0;
  margin-bottom: 30px;
  font-size: 18px;
  color: #020202;
  line-height: 1.2;
}
