.row {
  display: flex;
  flex-direction: row;
  border-bottom: 1px solid #ccc;

  span {
    flex: 0 0 25%;
    padding: 5px;
    line-height: 2;

    &.narrow {
      flex: 0 0 10%;
      text-align: center;
    }
  }

  &--header {
    span {
      font-weight: 600;
    }
  }
}